import { useEffect, useState } from "react";
import YouTube from "react-youtube";
import { LinkButton } from "../Button/LinkButton/LinkButton";
import Modal from "../Modal/Modal";
import "./EvaluateModal.css";

const EvaluateModal = ({ visible, onClose }) => {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    // Fonction pour mettre à jour les dimensions
    const handleResize = () => {
      setDimensions({ width: window.innerWidth, height: window.innerHeight });
    };

    // Ajouter un écouteur pour les changements de taille d'écran
    window.addEventListener("resize", handleResize);

    // Nettoyage de l'écouteur lors du démontage du composant
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (!visible) {
    return null;
  }

  return (
    <Modal isOpen={true} onClose={onClose}>
      <div className="evaluate">
        <div className="groupTitle">
          <div className="title">
            Accédez à notre levée de fonds WE DO GOOD !
          </div>
          <div className="subTitle">
            Et contribuez au succès de DomiSimpli !
          </div>
        </div>
        <div className="content">
          <div className="leftPart">
            <div className="phase">La phase d’évaluation</div>
            <div>
              Aidez-nous à propulser notre projet sur WE DO GOOD !
              <br />
              <br />
              Notre projet est en phase d’évaluation sur la plateforme WE DO
              GOOD, et nous avons besoin de vous pour franchir cette étape clé.
              En prenant quelques minutes pour l’évaluer, vous jouerez un rôle
              essentiel dans le lancement de la prochaine phase : la levée de
              fonds.
              <br />
              <br />
              👉 Comment faire ?<br />
              Pas d’inquiétude, on vous simplifie la vie ! Nous avons préparé un
              tutoriel clair et détaillé qui vous guidera pas à pas. Vous y
              découvrirez comment accéder au projet, consulter les détails et
              partager votre avis.
              <br />
              <br />
              Votre soutien compte énormément, et chaque évaluation nous
              rapproche un peu plus de notre objectif. Alors, prêt(e) à
              rejoindre l’aventure et à faire la différence ?
            </div>
          </div>
          <div>
            <div className="video">
              <YouTube
                className="youtube"
                videoId="JQCvphc5s_s" // Remplace par l'ID de ta vidéo
                // onReady={videoOnReady}
                opts={{
                  height: `${dimensions.width <= 430 ? 245 : 365}`,
                  width: `${
                    dimensions.width <= 430 ? dimensions.width * 0.8 : 438
                  }`,
                  playerVars: {
                    //autoplay: 1, // Démarre la vidéo automatiquement
                  },
                }}
              />
            </div>
          </div>
        </div>
        <LinkButton
          to="https://www.wedogood.co/domisimpli/"
          text="Évaluer DomiSimpli"
          style="filled"
        />
      </div>
    </Modal>
  );
};

export default EvaluateModal;
