import { useEffect } from "react";

const Modal = ({ isOpen, onClose, children }) => {
  useEffect(() => {
    // Empêche le défilement du body quand la modale est ouverte
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    // Cleanup lorsque le composant est démonté ou la modale est fermée
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div style={styles.overlay}>
      <div style={styles.modal}>
        <div style={styles.closeButton} onClick={onClose}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.494 5.48064C14.1466 7.13338 14.973 7.95974 16 7.95974C17.027 7.95974 17.8534 7.1334 19.506 5.48068L23.0132 1.97362C23.8284 1.15846 24.2362 0.750723 24.6602 0.505903C26.793 -0.725437 28.5494 0.496663 30.0264 1.97362C31.5034 3.4506 32.7254 5.20714 31.4942 7.33986C31.2494 7.7639 30.8418 8.1715 30.0266 8.98666L26.5198 12.4934C24.867 14.1462 24.0402 14.973 24.0402 16C24.0402 17.027 24.8666 17.8534 26.5194 19.506L30.0264 23.0132C30.8416 23.8284 31.2494 24.236 31.4942 24.66C32.7254 26.7929 31.5034 28.5494 30.0264 30.0263C28.5496 31.5035 26.7928 32.7255 24.6602 31.4941C24.2362 31.2495 23.8284 30.8419 23.0132 30.0263L19.5066 26.5198C17.8544 24.8676 17.027 24.0402 16 24.0402C14.9732 24.0402 14.1458 24.8674 12.4936 26.5198L8.98682 30.0263C8.17156 30.8419 7.76392 31.2495 7.33986 31.4941C5.2073 32.7255 3.45043 31.5035 1.97361 30.0263C0.496554 28.5494 -0.725366 26.7929 0.505894 24.66C0.750734 24.236 1.15835 23.8284 1.97361 23.0132L5.48064 19.506C7.13338 17.8534 7.95976 17.027 7.95976 16C7.95976 14.973 7.13296 14.1462 5.4802 12.4934L1.97345 8.98666C1.15831 8.1715 0.750714 7.7639 0.505894 7.33986C-0.725426 5.20714 0.496654 3.4506 1.97361 1.97362C3.45059 0.496663 5.20714 -0.725437 7.33986 0.505903C7.7639 0.750723 8.17162 1.15844 8.9868 1.9736L12.494 5.48064Z"
              fill="#b5bdc9"
            />
          </svg>
        </div>
        {children}
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 10000,
    paddingTop: "32px",
    paddingBottom: "32px",
  },
  modal: {
    backgroundColor: "#DEDCF0",
    borderRadius: "16px",
    minWidth: "300px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    position: "relative",
    overflowY: "auto",
    maxHeight: "90%",
    maxWidth: "90%",
  },
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    background: "transparent",
    border: "none",
    fontSize: "20px",
    cursor: "pointer",
  },
};

export default Modal;
